import React, { useState } from "react";
import useFormHook from '../hooks/FormHook';
import ReCAPTCHA from "react-google-recaptcha";
import AppInsightsService from "../services/AppInsightsService";
import sendEmail from '../services/EmailService';
import './Contact.css';

let resultMessage = '';
const insights = AppInsightsService.getSingletonInstance();

const Contact = () => {
    const [sending, setSending] = useState(false);
    console.debug('contact form init')
    const submitCallback = async () => {
        resultMessage = '';
        //validate recaptcha
        if (!inputs.captcha || inputs.captcha.length === 0) {
            alert('Please check the RECAPTCH field as required');
        } else {
            setSending(true);
            // TODO validate fields
            //compose email
            let body = {
                name: `Name: ${inputs.FirstName} ${inputs.LastName}`,
                email: `Email: ${inputs.Email}`,
                phone: `Phone: ${inputs.Phone}`,
                message: `Message: ${inputs.Message}`
            };
            let failed = false;
            // Send email to backend api or logic app url if hosted in static web app via env settings (see Constants.js)
            try {
                await sendEmail(body);
                resultMessage = 'Thankyou, your message has been sent.'
                insights.trackEvent({ name: 'Email sent' }, { name: body.name, email: body.email });
            } catch (error) {
                insights.trackException(error);
                resultMessage = 'An error occurred. Please try again later.'
                failed = true;
            }
            setSending(false);
            if (failed === false) {
                handleReset();
            }
        }
    }

    const { inputs, handleInputChange, handleSubmit, handleReset } = useFormHook({ FirstName: '', LastName: '', Email: '', Phone: '', Message: '' }, submitCallback);

    const onChange = (value) => {
        inputs.captcha = value;
    }

    const spinner = () => {
        if (sending === true) {
            return (
                <div>
                    <br />
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Sending...</span>
                    </div>
                </div>)
        } else {
            return (
                <input className="btn btn-primary" type="submit" value="Send" />
            )
        }
    }

    return (
        <div className="content">
            <h2>Contact</h2>
            <br />
            <div className="col-12 col-md-8">
                <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="form-group">
                        <label htmlFor="FirstName">First Name:</label>
                        <input className="form-control" type="text" required name="FirstName" onChange={handleInputChange} value={inputs.FirstName} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="LastName">Last Name:</label>
                        <input className="form-control" type="text" required name="LastName" onChange={handleInputChange} value={inputs.LastName} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="Email">Email:</label>
                        <input className="form-control" type="text" required name="Email" onChange={handleInputChange} value={inputs.Email} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="Phone">Phone:</label>
                        <input className="form-control" type="text" name="Phone" onChange={handleInputChange} value={inputs.Phone} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="Message">Enquiry:</label>
                        <textarea className="form-control" name="Message" rows="10" required onChange={handleInputChange} value={inputs.Message} />
                    </div>
                    <div className="form-group captcha">
                        <ReCAPTCHA
                            sitekey="6Lf36pglAAAAAGzLdUwnAxmEZnpdhysyi71kXmT_"
                            onChange={onChange}
                        />
                    </div>
                    {spinner()}
                    <div><br /><p>{resultMessage}</p></div>
                </form>
            </div>
        </div>
    );
}

export default Contact;