import React from 'react';
import './App.css';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Hero from './components/Hero';
import NavBar from './components/Navbar';
import Footer from './components/Footer';
import About from './pages/About';
import { BrowserRouter as Router, Route, Routes, Outlet } from "react-router-dom";
import Constants from './Constants';
import Privacy from './pages/Privacy';
import Showcase from './pages/Showcase';
import Portfolio from './pages/Portfolio';

function AppWrapper() {
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Outlet />
          </div>
        </div>
      </div>
    </main>);
}

function HeroWrapper() {
  return (<>
    <Hero></Hero>
    <AppWrapper></AppWrapper>
  </>
  )
}

function App() {
  return (
    <Router>
      <NavBar routes={Constants.Routes}></NavBar>
      <Routes>
        <Route element={<HeroWrapper />}>
          <Route exact path="/" element={<Home />} />
        </Route>
        <Route element={<AppWrapper />}>
          <Route path="/showcase" element={<Showcase />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
        </Route>
      </Routes>
      <Footer></Footer>
    </Router>
  );
}

export default App;
