import { useState } from 'react';

const useFormHook = (initialValues, callback) => {
  const [inputs, setInputs] = useState(initialValues);
  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
      callback();
    }
  }
  const handleInputChange = (event) => {
    event.persist();
    setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
  }
  const handleReset = () => {
    // TODO abstract the reset so formhook can be reused
    setInputs(inputs => ({ ...inputs, 'FirstName': '' }));
    setInputs(inputs => ({ ...inputs, 'LastName': '' }));
    setInputs(inputs => ({ ...inputs, 'Email': '' }));
    setInputs(inputs => ({ ...inputs, 'Phone': '' }));
    setInputs(inputs => ({ ...inputs, 'Message': '' }));
    setInputs(inputs => ({ ...inputs, 'captcha': '' }));
  }
  return {
    handleSubmit,
    handleInputChange,
    inputs,
    handleReset
  };
}

export default useFormHook;