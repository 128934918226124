import React from 'react';
import './Footer.css';

const REACT_VERSION = React.version;
function Footer() {
    return (
        <footer className="mt-auto">
            <div className="container-fluid">
                <div className="row flex-xl-nowrap">
                    <div className="col-md-4">
                        <p>
                            Copyright ©{(new Date().getFullYear())} Chad Paynter
                        </p>
                    </div>
                    <div className="col-md-4">
                        <p className="text-md-center">
                            <i>built with   </i><a href="https://reactjs.org/" target="_blank" rel="noreferrer" className="link-unstyled"><span className="react-logo" alt="react.js">&nbsp;</span><b>    react.js</b><i>{REACT_VERSION} </i></a>
                            &nbsp;|&nbsp;<a href="https://getbootstrap.com/" target="_blank" rel="noreferrer" className="link-unstyled"><span className="bootstrap-logo" alt="Bootstrap">&nbsp;</span> <b>bootstrap </b><i>4.6.0 </i></a>
                            &nbsp;|&nbsp;<a href="https://azure.microsoft.com/" target="_blank" rel="noreferrer" className="link-unstyled"><span className="azure-logo" alt="Azure">&nbsp;</span> <b>azure</b></a>
                        </p>
                    </div>
                    <div className="col-md-4 order-first order-md-0">
                        <p className="text-md-end">
                            <a href="https://linkedin.com/in/chadpaynter/">LinkedIn</a> | <a href="https://github.com/degero">Github</a> | <a href="/privacy">Privacy</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer >
    )
}

export default Footer;
