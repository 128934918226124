
import React from 'react';
import './Hero.css';

function Hero() {
    if (window.location.pathname !== '/')
        return null
    else {
        return (
            <div className="hero">
                Ready to bring your cloud tech vision to reality
            </div>
        );
    }
}

export default Hero;