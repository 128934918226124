import angular1 from '../assets/img/portfolio/portfolio-angular-1.png';
import angular2 from '../assets/img/portfolio/portfolio-angular-2.png';
import angular3 from '../assets/img/portfolio/portfolio-angular-3.png';
import ionic1 from '../assets/img/portfolio/portfolio-ionic-1.jpg';
import nextdesktop1 from '../assets/img/portfolio/portfolio-next-desktop-1.png';
import nextdesktop2 from '../assets/img/portfolio/portfolio-next-desktop-2.png';
import nextdesktop3 from '../assets/img/portfolio/portfolio-next-desktop-3.png';
import nextmobile1 from '../assets/img/portfolio/portfolio-next-mobile-1.png';
import nextmobile2 from '../assets/img/portfolio/portfolio-next-mobile-2.png';
import nextmobile3 from '../assets/img/portfolio/portfolio-next-mobile-3.png';

function Portfolio() {
    return (
        <div className="content">
            <h2>Portfolio</h2>
            <div className="row">
                <h4>Tailwind CSS Implementations</h4>
                <p>&nbsp;</p>
            </div>
            <div className="row">
                <div className="col-xl-6">
                    <h5>SportsAccom NextJS Tailwind <a href="https://www.sportsaccom.pro" target="_blank">web</a></h5>
                    <p><img src={nextdesktop1} height="100%" width="100%" /></p>
                    <p><img src={nextdesktop2} height="100%" width="100%" /></p>
                    <p><img src={nextdesktop3} height="100%" width="100%" /></p>
                </div>
                <div className="col-xl-6">
                    <h5>SportsAccom NextJS Tailwind <a href="https://www.sportsaccom.pro" target="_blank">mobile</a></h5>
                    <p><img src={nextmobile1} height="50%" width="50%" /><img src={nextmobile2} height="50%" width="50%" /><img src={nextmobile3} height="50%" width="50%" /></p>
                </div>

            </div>
            <div className="row">
                <p>&nbsp;</p>
            </div>
            <div className="row">
                <h4>PSDs / Figma diagrams in bootstrap</h4>
                <p>&nbsp;</p>
            </div>
            <div className="row">
                <div className="col-xl-6">
                    <h5>Responsive web implementation in Angular</h5>
                    <p><img src={angular1} height="50%" width="90%" /></p>
                    <p><img src={angular2} height="50%" width="90%" /></p>
                    <p><img src={angular3} height="50%" width="90%" /></p>
                </div>
                <div className="col-xl-6">
                    <h5>Ionic mobile app development</h5>
                    <p><img src={ionic1} height="50%" width="90%" /></p>
                </div>
            </div>
        </div>
    );
}
export default Portfolio;