import React from 'react';
import { Link } from "react-router-dom";

// We can use a seperate component for each list item or 
function NavItem(props) {
    return (
        <li className="nav-item"><Link className="nav-link" to={props.route}>{props.label}</Link></li>
    )
}


function getHeaderClasses(props) {
    if (props.route === '/') {
        return '';
    } else {
        return ' mb-auto';
    }
}

function Navbar(props) {

    const items = props.routes;
    // can use map here and {this.listItems} in JSX or just the map in JSX
    const listItems = items.map((item) =>
        <li key={item.id}><Link to="{item.route}">{item.label}</Link></li>
    );

    return (<header className={'navbar navbar-expand navbar-dark flex-column flex-md-row bd-navbar px-lg-4' + getHeaderClasses(items)}>
        <div className="navbar-nav">
            <Link to="/" className="nav-link home-link"><b>Home</b></Link>
        </div>
        <div className="navbar-nav-scroll">
            <nav>
                <ul className="navbar-nav bd-navbar-nav flex-row">
                    <li key={0} className="nav-item"><Link className="nav-link" to={"https://blog.paynter.tech"}>Blog</Link></li>
                    {items.map((item) =>
                        <NavItem key={item.id} route={item.route} label={item.label}></NavItem>
                    )}
                </ul>
            </nav>
        </div>
    </header>);
}

export default Navbar;