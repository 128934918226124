import Constants from "../Constants";

const sendEmail = async (body) => {
    const url = process.env[Constants.EnvSettings.EmailEndpointUrl];
    await fetch(url, {
        method: 'POST', body: JSON.stringify(body), headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((res) => {
        if (res.status !== 200) {
            throw new Error("Failed to send email");
        }
    });
};

export default sendEmail;
