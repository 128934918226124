import React, { Component } from "react";
import profile from '../assets/img/profile12.jpg';
import az305 from '../assets/img/certs/az305.png';
import az104 from '../assets/img/certs/az104.png';
import az204 from '../assets/img/certs/az204.png';
import externalLink from '../assets/img/external-link.svg';
import './Home.css';
import { Link } from 'react-router-dom';
import Constants from '../Constants';
class Home extends Component {
    componentDidMount() {
        const script = document.createElement('script');
        script.setAttribute(
            'src',
            'https://cdn.credly.com/assets/utilities/embed.js');
        document.body.appendChild(script);
    }
    render() {
        return (
            <div className="content">
                <div className="row">
                    <div className="col-12">
                        <h2>Hi, my name is Chad.</h2>
                        <p>I am a cloud and devops engineer with extensive experience in Cloud architecture, Infrastructure management + monitoring, DevOps, IAC, CICD, Front/Backend development and Database design + development,  I have a solid successful project history of building solutions from the ground up using Microservices, Serverless, Kubernetes, Microsoft Azure, Azure Devops, Atlassian Bamboo, Typescript/Javascript, .Net C#, WPF/WCF, ASP.net Core/Webapi/minimal apis, React, Next.js, Tailwind, GraphQL, Node.js, Angular, HTML/CSS and more.</p>
                        <img src={profile} alt="Chad Paynter" className="profile-picture"></img>
                        <p>I specialise in cloud, web and desktop solutions having worked on greenfield and brownfield solutions in industries including Government, Mining, Healthcare and Insurance to name a few. My experience covers all aspects of the software lifecycle including scoping and analysis, software / cloud architecture, OAuth, TDD, software security, accessibility/usability, agile project management and DevOps practices.</p>
                        <p>I am familiar with working with BAs, Project Managers, Product Owners and other stakeholders to refine and improve software as well as offer suggestions for areas of improvement. I've worked on many successful software solutions both independently and as a team lead/scrum master or team member including 100% remote with team members in other timezones.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="order-last order-md-first col-sm-6">
                        <div className="badge-box">
                            {[
                                { img: az305, url: 'https://learn.microsoft.com/en-us/users/chadp/credentials/b07de81afef80ef1' },
                                { img: az104, url: 'https://learn.microsoft.com/api/credentials/share/en-us/ChadP/34CCB0C1F4E0696A?sharingId=A3795247C1F0D1B3' },
                                { img: az204, url: 'https://learn.microsoft.com/api/credentials/share/en-us/ChadP/6E76EB54B99B1C69?sharingId=A3795247C1F0D1B3' },
                            ].map(r =>
                            (
                                <div className="badge-item">
                                    <div className="text-center">
                                        <a className="mb-2 m" target="_blank" href={r.url} height="120px" width="110px" >
                                            <img src={r.img} height="120px" width="110px" />
                                        </a>
                                        <p>
                                            <a target="_blank" href={r.url}>
                                                Show credential<img src={externalLink} width="16px" height="16px" />
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            )
                            )}
                        </div>
                    </div>
                    <div className="order-first order-md-last col-sm-6">
                        <p>My background is a Bachelor of Information Technology at <a href="https://www.uq.edu.au/">The University of Queensland</a>, I also have certified training in <a href="https://azure.microsoft.com/" >Microsoft Azure</a> and <a href="https://www.peakxd.com.au/">User Experience / User centred design</a>.</p>
                        <p>I have a keen interest in the Cloud Solutions, Cloud AI Services, DevOps, Containerization and Serverless applications.</p>
                        <p>If you interested in using any of my skills from greenfield project dev to assessment of your cloud workloads, <Link to="/contact-us">please contact me here</Link>
                            <br /><br />Please checkout my <a href="https://blog.paynter.tech">blog</a>, <a href="/portfolio">portfolio</a> and <a href="/showcase">showcase</a>. To read about the technology I used to build/host this site see <Link to="/about">about</Link></p>

                    </div>
                </div>
            </div >
        );
    }
}

export default Home;