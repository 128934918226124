import React, { Component } from "react";
class About extends Component {
    render() {
        return (
            <div className="content">
                <h2>About</h2>
                <p>This site has been written solely by myself using the below</p>
                <h3>Technology stack</h3>
                <ul>
                    <li>React.js</li>
                    <li>Azure Storage Static Website Hosting</li>
                    <li>Azure CDN</li>
                    <li>Azure Logic Apps</li>
                    <li>Bootstrap</li>
                    <li>Github Actions</li>
                    <li>Azure Devops</li>
                </ul>
                <br/>
                <h3>Implementation</h3>
                <p>This site was based of create-react-app and is stored on github. The repo contains github actions to run CI builds on PRs and commits to main branch.<br/>
                An azure devops pipeline monitors the main branch for changes and initiates a deployment to azure storage as well as flushing the Azure CDN endpoint cache so the changes will appear immediately.<br/>
                Azure CDN handles the custom domain used here to deliver the SPA.<br/>
                Appinsights tracking is used as well as google analytics.<br/>
                The contact page sends JSON requests to a Azure Logic App which uses a Gmail connector to send the form details to an email address. It also uses Google CAPTCHA.
                </p>
                <p>&nbsp;</p>
            </div>
        );
    }
}

export default About;