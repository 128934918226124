import { Link } from "react-router-dom";
import nextdesktop1 from '../assets/img/portfolio/portfolio-next-desktop-1.png';
function Showcase() {
    const hostName = window?.location?.hostname
    const bikeshopUrl = hostName && hostName !== 'localhost' ? `https://${hostName.replace('chad', 'bikeshop')}` : 'https://bikeshop.paynter.tech';

    return (
        <div className="content">
            <h2>Showcase</h2>
            <h3>
                <strong>SportsAccom</strong> - Travel planning tool for hotels suited to sportspeople.
            </h3>
            <br />
            <p>
                <b>C# Minimalapi MongoDB GraphQL NextJS React Tailwind Responsive WebApp greenfield development as sole engineer.</b>
                <br />
                <br />
                <i>App Features:</i>
                <br />
                Implementation of front/backend code and responsive html/css with github copilot assistance. Google places, device location services and Google maps used. Site data context based Azure AI GPT chatbot with cognitive search. Prisma/Pothos/YogaGraphQL implemented for api with MongoDB. Tests implemented in Vitest and Cypress, test execution and coverage reports in CI. GraphQL security hardening. C# minimal api azure app service for non-data services (email, chatbot etc).
                <br />
                <br />
                <i>Cloud features:</i>
                <br />
                Security review of app and api including Azure Defender recommendations. Contact form api via Azure Logic Apps email connector. Github actions and Azure DevOps pipelines setup for CI/CD to Static Web Apps and App Service. Azure CDN and Blob Storage for Image and site delivery optimisation. Azure alerting and monitoring for high error counts, budget limits, availability test failures. AppInsights client side and NodeJS server side implemented.
                <br />
                <br />
                <i>Social, SEO, App optimisation:</i>
                <br />
                Nextjs performance profiling and adjustment with Lighthouse and React dev tools. CDN Image optimisation. Cross browser/device testing in BrowserStack. SEO optimisation using best practices and google, bing reports. Hotjar UX tracking and app adjustments based on user interaction. Promotional social videos/posts using AI tooling.
            </p><p>
                <i>see <Link to="/portfolio">Portfolio</Link> for responsive designs</i>
            </p>
            <p><img src={nextdesktop1} height="100%" width="100%" /></p>
            <p>&nbsp;</p>
            <h3>
                <strong>Web app demo</strong> Bikeshop - React / Redux / ExpressJS API / JSON Db
            </h3>
            <p>
                <a href={bikeshopUrl}>Bike shop</a> - <a href="https://github.com/degero/bikeshop">Github repo</a>
            </p>
            <p>&nbsp;</p>
            <h3><strong>Web app template</strong> React forms + Azure Storage + Azure Cosmos Db + asp.net core + Terraform</h3>
            <p><a href="https://github.com/degero/reactformsazure">Github repo</a></p>
            <p>&nbsp;</p>
            <h3><strong>Web app template</strong> VSCode app with typescript, webpack, jest, prettier, lint</h3>
            <p><a href="https://github.com/degero/vscodetsprojtemplate">Github repo</a></p>
            <p>&nbsp;</p>
            <h3><strong>Docker/Compose</strong> Nginx reverse proxy template for quick app setup </h3>
            <p><a href="https://github.com/degero/nginx-proxy-samples">Github repo</a></p>
            <p>&nbsp;</p>
            <h3><strong>Docker</strong> Dotnet core app and configurations for AWS/Azure/Gcloud deployment</h3>
            <p><a href="https://github.com/degero/netcoremvcdocker">Github repo</a></p>
        </div >
    );
}
export default Showcase;
