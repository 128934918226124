
class Constants {
    static Routes = [
        { id: 1, route: '/showcase', label: 'Showcase' },
        { id: 2, route: '/portfolio', label: 'Portfolio' },
        { id: 3, route: '/contact-us', label: 'Contact' },
        { id: 4, route: '/about', label: 'About' },
        { id: 5, route: '/privacy', label: 'Privacy' }
    ];
    static Server = {
        hostname: 'paynter.tech'
    };
    static EnvSettings = {
        EmailEndpointUrl: 'REACT_APP_EMAIL_ENDPOINT_URL'
    }
}

export default Constants;