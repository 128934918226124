import { ApplicationInsights, ICustomProperties, IEventTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web'

class AppInsightsService {

    private appInsights: ApplicationInsights | null;

    constructor() {
        this.appInsights = null;
    }

    public initialise(): void {

        this.appInsights = new ApplicationInsights({
            config: {
                connectionString: 'InstrumentationKey=5726eafa-e720-413d-849c-4075e36d02b8;IngestionEndpoint=https://australiasoutheast-0.in.applicationinsights.azure.com/'
            }
        });
        this.appInsights.loadAppInsights();
        this.appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
    }

    public static getSingletonInstance(): AppInsightsService {
        if (!(window as any).appInsights) {
            const svc = new AppInsightsService();
            svc.initialise();
            window.appInsights = svc;
        }
        return window.appInsights;
    }

    public trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties): void {
        console.debug('tracking event');
        this.appInsights?.trackEvent(event, customProperties);
    }

    public trackException(error: Error) {
        this.appInsights?.trackException({ exception: error, severityLevel: SeverityLevel.Error });
    }
}

export default AppInsightsService;