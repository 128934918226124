import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import AppInsightsService from './services/AppInsightsService';
import { createRoot } from 'react-dom/client';
  
// ReactDOM.render(<App />, document.getElementById('app'));
const root = createRoot(document.getElementById('app'));
root.render(<React.StrictMode><App/>
  </React.StrictMode>);

// init app insights
AppInsightsService.getSingletonInstance();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
